import { Vue, Component, Watch } from 'vue-property-decorator';
import TaskList from '@/components/tasklistcomponent/TaskList.vue';
import { ScreenText } from '@/lang/ScreenText';
import programConfigureStore from '@/store/modules/programConfigure';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import commonEntry from '@/store/modules/commonEntry';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import { deleteSite } from './../../services/userService/users-api';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import UIkit from 'uikit';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';

const PROGRAM_CONST = {
  PROGRAM_CONFIG_SUCCESS: 'Program Configured Successfully.',
  TYPE_SITE_PANEL: 'sitePanel',
  LABEL_SITE: 'site/session',
  LABEL_PROGRAM: 'program',
  LABEL_MANAGER: 'manager',
  LABEL_SESSION_MANAGER: 'sessionManager',
  LABEL_SELECT_SITE: 'Select Site/Session',
  LABEL_AFTER_PROGRAM_START_DATE: 'After Program Start Date',
  LABEL_DAYS: 'days',
  SAVE_BTN_CLICK: 'saveButtonClick',
  NUM_OF_SCHOLARS: 'numberScholars',
  ANSWER_YES: 'YES',
  ANSWER_NO: 'NO',
  QUESTION_VIEW: 'questionView',
  LIST_VIEW: 'listView',

  LIST_SESSION_MANAGER: 'Session Manager List',
  MY_LIST: 'My List',
  FULL_LIST: 'Full List',

  ACTION_RESTART: 'Restart',
  ACTION_FILLING: 'filling',
  ACTION_LIST_PREVIEW: 'list-preview',

  KEY_ID: 'id',
  KEY_QUSTION_ID: 'questionId',
  KEY_TITLE: 'title',
  KEY_DESCRIPTON: 'description',
  KEY_TASKS: 'tasks',
  KEY_CMS_ANS_ID: 'cmsAnswerId',
  KEY_STATUS: 'status',
  KEY_CREATED_BY: 'createdBy',
  KEY_CREATED_AT: 'createdAt',

  ROUTE_CONFIG_PROGRAM: 'Configure Program',
  ROUTE_CREATE_PROGRAM: 'Create Program',
  ROUTE_EDIT_PROGRAM: 'Edit Account',
  LABEL_CONFIG_PROGRAM: 'Configure Your Program',
  LABEL_CRATE_NEW_PROGRAM: 'Create New Program',
  LABEL_EDIT_PROGRAM: 'Edit Program',

  ELEMENTS_INVITE_MANAGER_SENT: 'invite-manager-sent',

  ERR_PHONE_NUMBER: 'Please fill 10digits PhoneNumber!!',
  ERR_REQUIRED_FIELD_MISSING: 'Please Fill all required fields!!',
  ERR_DUPLICAT_SIDE: 'Hmm. You already have a Site/Session with this name. Try using a different name, instead!',

  ROLE_SESSION_ADMIN: 'SessionAdmin',
  ROLE_PROGRAM_ADMIN: 'ProgramAdmin',

  ERR_SITE_PANEL: 'Please add in at least 1 site/session!!',
  ERR_SESSION_MANAGER: 'Please add in at least 1 session manager!!'
};
@Component({
  components: {
    'task-list': TaskList,
    'datepicker': DatepickerComponent,
    'progressButton': ProgressButton
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    const isProgramConfigured = APP_UTILITIES.getCookie('isProgramConfigured');
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    const roleID = JSON.parse(userRoles)[0].roleId;
    const confirmisFinalSubmit = isProgramConfigured
      ? JSON.parse(isProgramConfigured)
      : false;
    if ((!confirmisFinalSubmit && roleID == 5) || (!confirmisFinalSubmit && roleID == 4 && !this.$data.routeToChange)) {
      next(false);
    }
    else {
      next();
      if (from.path == '/configureprogram') {
        // this.$emit('loadNav');
      }
    }
  }
})

export class ProgramPage extends Vue {
  readonly STYLE = APP_CONST.STYLE;
  private objScreenText: ScreenText = new ScreenText();
  public accordionTilesArray: any = JSON.parse(JSON.stringify(APP_CONST.PROFILE_ACCORDION_TILES));
  public cities: string[] = APP_UTILITIES.fetchCities(APP_CONST.COUNTRY_NAME);
  public states: string[] = APP_UTILITIES.fetchStates(APP_CONST.COUNTRY_NAME);
  public firstTimeSequenceCompleteCheck: boolean[] = APP_CONST.PROFILE_ACCORDION_TILES.map((item) => item.openStatus);
  public dropdownToOpen: { panelIndex: number; dropType: string; openStatus: boolean } = {
    panelIndex: APP_CONST.MINUS_ONE,
    dropType: '',
    openStatus: APP_CONST.FALSE
  };
  public programId: number = 0;
  public programAdminId: number = 0;
  public userDetailsObj: any = {};
  public checkForFilled: boolean = APP_CONST.TRUE;
  public checkForFilledProgram: boolean = APP_CONST.TRUE;
  public checkForFilledManager: boolean = APP_CONST.TRUE;
  public enableSave: string | boolean = '';
  public intervalValue: any;
  public dateIsGreater: boolean = false;
  public slides: number = 5;
  public active: number = 0;
  public showCustomSequence: any = {
    defaultVisible: true,
    questionVisible: false,
    listVisible: false
  };
  public styleObject: any = {

  };
  public tabSelection: string = 'list-preview';
  public enabledAnswer: any = [];
  public tooltipDisplay: boolean = false;
  public checkCheckedStatus: any = { radio1: false, radio2: true };
  public questionsFetch: any = [];
  public questionsData: any = [];
  public tasks: any = APP_CONST.TASKS_LIST;
  public newTasks: any = [];
  public pageTitle: string = '';
  public arrayNoSelected: any = [];
  public activateInviteButton: any = false;
  public accountId: number = 0;
  public routeToChange: boolean = true;
  public programDateChanged: boolean = false;
  public showInvitePopup: boolean | string = false;
  public phoneNumberLength: boolean = true;
  public showPopCondition: boolean = false;
  public overallProdTasks: any = [];
  public remainingTasksProd: any = [];
  public remainingUpdatedTasks: any = [];
  public tickStatuses: any = [false, false, false, false];
  public populatedSaveObj: any = {};
  public routeName: string = '';
  public zipcodeValidationError: boolean = false;
  public emailValidationError: boolean = false;
  public sitePanelError: boolean = false;
  public sitePanelDuplicateError: boolean = false;
  public duplicateEmailError: boolean = false;
  public sessionMgrPanelError: boolean = false;
  public inviteResponseText: string = '';
  public openDropSiteStatus: boolean = false;
  public remainTasksAPIresponse: any = [];
  public fNameError: any = false;
  public lNameError: any = false;
  public fNameMinimumLengthError: any = false;
  public lNameMinimumLengthError: any = false;
  public phoneNoError: any = false;
  public showToolTip: boolean = false;
  public COUNT = 0;
  private addSiteMount: boolean = false;
  private hover: boolean = false;
  private hoverText: string = '';
  private hoverVal: string = '';
  private hoverStyleObj: any = {};
  private isMobileView: boolean = false;
  public daysObj: any = APP_CONST.GET_DAYS;
  public siteDaysArray: Array<string> = [];
  private siteStartDateErr: boolean = false;
  private siteEndDateErr: boolean = false;
  public dateIsNotGreater: boolean = false;
  public roleId: number = 0;
  apiResponseCode: number = 0;
  isValidate: boolean = true;

  getGlobalState = getModule(GlobalModule, store);

  get initialTasks() {
    return this.tasks;
  }

  @Watch('initialTasks', { deep: true })
  checkTasksUpdate(data: any) {
    this.newTasks = data;
  }

  get updatedProgramResponse() {
    return programConfigureStore.configureResponse;
  }

  get updatedProgramConfig() {
    return programConfigureStore.updatedProgramObj;
  }

  get updatedQuestions() {
    return this.questionsFetch;
  }

  get updatedSlides() {
    return this.slides;
  }

  get updatedDropdown() {
    return this.dropdownToOpen;
  }

  get updatedTiles() {
    return this.accordionTilesArray;
  }


  get updateEnableSave() {
    return this.enableSave;
  }

  get updateStyleObject() {
    this.styleObject = {
      margin: `0px ${this.questionsFetch.length / 5 * 3}px`
    };
    return this.styleObject;
  }

  get getUserDetail() {
    return commonEntry.userDetail;
  }

  created() {
    // this.$emit('loadNav');
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  public openTile(index: number, type = '') {
    if (this.firstTimeSequenceCompleteCheck[index]) {
      const indexesCheck = [0, 1, 2];
      const openStatus = this.accordionTilesArray[index].openStatus;
      openStatus
        ? this.accordionTilesArray[index].openStatus = APP_CONST.FALSE
        : this.accordionTilesArray[index].openStatus = APP_CONST.TRUE;
      indexesCheck.forEach((indexItem: any) => {
        if (indexItem !== index) {
          this.accordionTilesArray[indexItem].openStatus = APP_CONST.FALSE;
        }
      });
    }
    if (type === '') {
      this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((sitePanel: any, sitePanelIndex: number) => {
        if (sitePanelIndex == APP_CONST.ZERO) {
          sitePanel.openStatus = true;
          const selectedDaysStr = this.accordionTilesArray[APP_CONST.ONE].sitePanels[sitePanelIndex].selectedDays.value;
          this.siteDaysArray = selectedDaysStr.length
            ? selectedDaysStr.split(',')
            : [];
        }
        else {
          sitePanel.openStatus = false;
        }

      });
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((sitePanel: any, sitePanelIndex: number) => {
        if (sitePanelIndex == APP_CONST.ZERO) {
          sitePanel.openStatus = true;
        }
        else {
          sitePanel.openStatus = false;
        }
      });
    }
    else {
      this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((sitePanel: any, sitePanelIndex: number) => {
        sitePanel.openStatus = true;
        const selectedDaysStr = this.accordionTilesArray[APP_CONST.ONE].sitePanels[sitePanelIndex].selectedDays.value;
        this.siteDaysArray = selectedDaysStr.length
          ? selectedDaysStr.split(',')
          : [];
      });
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((sitePanel: any, sitePanelIndex: number) => {
        sitePanel.openStatus = true;

      });
    }

    if (!this.errorIndicator(this.accordionTilesArray[index], index)) {
      this.removeErrorMsg(index);
    }
  }




  get updateSequenceCheck() {
    return this.firstTimeSequenceCompleteCheck;
  }

  public skipTab(index: number) {
    if (index === APP_CONST.TWO && this.routeName === 'EditProgram') {
      return;
    }
    const ele: any = document.getElementById('section-' + index);
    if (ele) {
      ele.scrollIntoView(true);
    }
    const indexCheck = index < APP_CONST.PROFILE_ACCORDION_TILES.length - APP_CONST.ONE;
    const tilesArray = this.accordionTilesArray;
    if (index === APP_CONST.ZERO) {
      this.accordionTilesArray[index + APP_CONST.ONE].sitePanels.length > 0 && (this.accordionTilesArray[index + APP_CONST.ONE].sitePanels[APP_CONST.ZERO].openStatus = APP_CONST.TRUE);
      if (this.accordionTilesArray[index + APP_CONST.ONE].sitePanels.length > 0) {
        const selectedDaysStr = this.accordionTilesArray[APP_CONST.ONE].sitePanels[APP_CONST.ZERO].selectedDays.value;
        this.siteDaysArray = selectedDaysStr.length
          ? selectedDaysStr.split(',')
          : [];
      }

    }
    if (index === APP_CONST.ONE) {
      this.accordionTilesArray[index].sitePanels.forEach((item: any, siteIndex: number) => {
        if (siteIndex === APP_CONST.ZERO) {
          item.openStatus = APP_CONST.TRUE;
          const selectedDaysStr = this.accordionTilesArray[APP_CONST.ONE].sitePanels[siteIndex].selectedDays.value;
          this.siteDaysArray = selectedDaysStr.length
            ? selectedDaysStr.split(',')
            : [];
        }
        else {
          item.openStatus = APP_CONST.FALSE;
        }
      });
    }
    if (index === APP_CONST.TWO || index === APP_CONST.ONE) {
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((item: any, siteIndex: number) => {
        if (siteIndex === APP_CONST.ZERO) {
          item.openStatus = APP_CONST.TRUE;
        }
        else {
          item.openStatus = APP_CONST.FALSE;
        }
      });
    }

    if (indexCheck) {
      this.firstTimeSequenceCompleteCheck[index + APP_CONST.ONE] = APP_CONST.TRUE;
      tilesArray[index].openStatus = APP_CONST.FALSE;
      tilesArray[index + APP_CONST.ONE].openStatus = APP_CONST.TRUE;
    }
    else {
      this.firstTimeSequenceCompleteCheck[index] = APP_CONST.TRUE;
      tilesArray[index].openStatus = APP_CONST.FALSE;
    }
  }

  public clearForm(accordionIndex: number) {
    const fetchObj = this.accordionTilesArray[accordionIndex];
    let clearObjArray: any = [];
    this.tickStatuses[accordionIndex] = false;
    switch (accordionIndex) {
      case APP_CONST.ZERO:
        clearObjArray = APP_CONST.PROGRAM_KEYS_EXCLUDE;
        for (const item in fetchObj) {
          if (clearObjArray.indexOf(item) !== APP_CONST.MINUS_ONE) {
            if (item === PROGRAM_CONST.NUM_OF_SCHOLARS) {
              if (this.$route.name !== 'Configure Program') {
                fetchObj[item].value = '';
              }
            }
            else {
              fetchObj[item].value = ((this.routeName === 'EditProgram') && (item === 'programStartDate')) || (this.routeName === 'EditProgram') && (item === 'programEndDate')
                ? fetchObj[item].value
                : '';
            }
          }
        }
        break;
      case APP_CONST.ONE:
        this.sitePanelDuplicateError = false;
        this.siteDaysArray = [];
        clearObjArray = APP_CONST.SITE_KEYS_EXCLUDE;
        fetchObj.sitePanels.forEach((siteObj: any) => {
          for (const siteElement in siteObj) {
            if (clearObjArray.indexOf(siteElement) !== APP_CONST.MINUS_ONE) {
              siteObj[siteElement].value = '';
            }
          }
        });
        this.accordionTilesArray[2].sessionManagers.length > 0 && this.accordionTilesArray[2].sessionManagers.forEach((siteMrg: any, index: number) => {
          siteMrg.citeObjects[0].siteName.value = 'Select Site/Session';
        });
        this.togglePanel(0, 1, 'clearForm');
        break;
      case APP_CONST.TWO:
        clearObjArray = APP_CONST.MANAGE_KEYS_EXCLUDE;
        fetchObj.sessionManagers.forEach((siteObj: any) => {
          for (const siteElement in siteObj) {
            if (clearObjArray.indexOf(siteElement) !== APP_CONST.MINUS_ONE) {
              siteObj[siteElement].value = '';
            }
          }
        });

        this.accordionTilesArray[2].sessionManagers.length > 0 && this.accordionTilesArray[2].sessionManagers.forEach((siteMrg: any, index: number) => {
          siteMrg.citeObjects[0].siteName.value = 'Select Site/Session';
        });
        this.togglePanel(0, 2, 'clearForm');
        break;
      case APP_CONST.THREE:
        break;
      default:
        break;

    }
    this.onKeyUP();
  }

  public checkForRemoveErr(type: string, panelLength: number, panelIndex: number) {
    let sitePanelStatus = true;
    if (type === PROGRAM_CONST.TYPE_SITE_PANEL) {
      this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((siteObj: any, index: number) => {
        if (index !== panelIndex) {
          for (const siteElement in siteObj) {
            if (APP_CONST.SITE_KEYS_EXCLUDE.indexOf(siteElement) !== APP_CONST.MINUS_ONE) {
              if (siteObj[siteElement].errorStatus === true) {
                sitePanelStatus = false;
                return sitePanelStatus;
              }
            }
          }
        }
      });
    }
    else {
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((sessionManager: any, sessionManagerIndex: number) => {
        if (sessionManagerIndex !== panelIndex) {
          for (const sessionElement in sessionManager) {
            if (APP_CONST.MANAGE_KEYS_EXCLUDE.indexOf(sessionElement) !== APP_CONST.MINUS_ONE) {
              if (sessionManager[sessionElement].errorStatus === true) {
                sitePanelStatus = false;
                return sitePanelStatus;
              }
            }
          }
        }
      });
    }

    return sitePanelStatus;
  }

  deleteSiteInPanel(siteId: number, accordionIndex: number, panelIndex: number) {
    deleteSite(siteId).then((res: any) => {
      if (res.status === APP_CONST.RESPONSE_200) {
        this.accordionTilesArray[accordionIndex].sitePanels.splice(panelIndex, APP_CONST.ONE);
      }
    });
  }

  removeErrorMessage = (type: string) => {
    switch (type) {
      case PROGRAM_CONST.LABEL_PROGRAM:
        this.checkForFilledProgram = true;
        break;
      case PROGRAM_CONST.LABEL_SITE:
        this.checkForFilled = true;
        break;
      case PROGRAM_CONST.LABEL_MANAGER:
        this.checkForFilledManager = true;
        break;
      default:
        break;
    }
  };

  public deletePanel(accordionIndex: number, panelIndex: number, siteDt?: any) {
    if (accordionIndex === APP_CONST.ONE) {
      const sitePanelsLength = this.accordionTilesArray[accordionIndex].sitePanels.length;
      if (sitePanelsLength === APP_CONST.ONE || this.checkForRemoveErr(PROGRAM_CONST.TYPE_SITE_PANEL, sitePanelsLength, panelIndex)) {
        this.removeErrorMessage(PROGRAM_CONST.LABEL_SITE);
      }
      if (this.$route.name == PROGRAM_CONST.ROUTE_EDIT_PROGRAM) {
        const siteId = this.accordionTilesArray[accordionIndex].sitePanels[panelIndex].siteId;
        if (!siteId) {
          this.accordionTilesArray[accordionIndex].sitePanels.splice(panelIndex, APP_CONST.ONE);
        }
        if (siteId > 0) {
          this.deleteSiteInPanel(siteId, accordionIndex, panelIndex);
        }
      }
      else {
        this.accordionTilesArray[accordionIndex].sitePanels.splice(panelIndex, APP_CONST.ONE);
        this.onKeyUP();
      }
      if (this.accordionTilesArray[accordionIndex].sitePanels.length === 0) {
        this.tickStatuses[APP_CONST.ONE] = false;
      }
    }
    else if (accordionIndex === APP_CONST.TWO) {
      const sessionManagerLength = this.accordionTilesArray[accordionIndex].sessionManagers.length;

      if (sessionManagerLength === APP_CONST.ONE || this.checkForRemoveErr(PROGRAM_CONST.LABEL_SESSION_MANAGER, sessionManagerLength, panelIndex)) {
        this.removeErrorMessage(PROGRAM_CONST.LABEL_MANAGER);
      }
      this.accordionTilesArray[accordionIndex].sessionManagers.splice(panelIndex, APP_CONST.ONE);
      this.onKeyUP();
      if (this.accordionTilesArray[accordionIndex].sessionManagers.length === 0) {
        this.tickStatuses[APP_CONST.TWO] = false;
      }
    }
  }

  public find_duplicate_in_array(arra1: any) {
    const object: any = {};
    const result: any = [];

    arra1.forEach(function (item: any) {
      if (!object[item.email.value]) {
        object[item.email.value] = 0;
      }
      object[item.email.value] += 1;
    });

    for (const prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }

    return result;
  }

  public find_duplicate_in_site(arra1: any) {
    const object: any = {};
    const result: any = [];

    arra1.forEach(function (item: any) {
      if (!object[item.siteName.value]) {
        object[item.siteName.value] = 0;
      }
      object[item.siteName.value] += 1;
    });

    for (const prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }
    return result;
  }

  hasNumber(myString: any) {
    return /\d/.test(myString);
  }

  public nextTab(accordionIndex: number, fromConfig?: string | undefined) {
    if (accordionIndex == APP_CONST.ZERO && this.COUNT == 0 && fromConfig == undefined) {
      this.addSite(accordionIndex + 1);
      this.COUNT++;
    }
    if (accordionIndex === APP_CONST.TWO && this.routeName === 'EditProgram') {
      return;
    }
    const ele: any = document.getElementById('section-' + accordionIndex);
    if (ele) {
      ele.scrollIntoView(true);
    }
    let progtick = true;
    let sitetick = true;
    let managetick = true;
    const accordionObj = this.accordionTilesArray[accordionIndex];

    if (accordionIndex === APP_CONST.ZERO) {
      this.checkForFilledProgram = APP_CONST.TRUE;
      for (const keyElement in accordionObj) {
        if (keyElement !== APP_CONST.OPEN_STATUS && keyElement !== APP_CONST.TEXT_AREA && keyElement !== APP_CONST.TEXT_VALUE) {
          if (accordionObj[keyElement].value === '' || accordionObj[keyElement].value === undefined) {
            this.checkForFilledProgram = APP_CONST.FALSE;
            accordionObj[keyElement].errorStatus = APP_CONST.TRUE;
          }
          else {
            accordionObj[keyElement].errorStatus = APP_CONST.FALSE;
          }
        }
      }
      if (this.checkForFilledProgram) {
        this.skipTab(accordionIndex);
      }

      Object.keys(this.accordionTilesArray[APP_CONST.ZERO]).forEach((item: any) => {
        if (typeof (this.accordionTilesArray[APP_CONST.ZERO][item]) == 'object') {
          if (this.accordionTilesArray[APP_CONST.ZERO][item].value === '') {
            progtick = false;
          }
        }
      });
      this.tickStatuses[APP_CONST.ZERO] = progtick;
    }
    else if (accordionIndex === APP_CONST.ONE) {
      this.checkForFilled = APP_CONST.TRUE;
      const sitePanels = accordionObj.sitePanels;
      if (sitePanels.length === 0) {
        this.sitePanelError = APP_CONST.TRUE;
        return false;
      }
      else {
        this.sitePanelError = APP_CONST.FALSE;
        sitePanels.forEach((item: any, index: number) => {
          for (const siteElement in item) {
            if (siteElement !== APP_CONST.OPEN_STATUS && siteElement !== APP_CONST.DELETE_POPUP) {
              if (siteElement !== APP_CONST.ADDRESS_2) {
                if (siteElement !== 'siteId') {
                  if (item[siteElement].value === '' || item[siteElement].value === undefined) {
                    this.checkForFilled = APP_CONST.FALSE;
                    item[siteElement].errorStatus = APP_CONST.TRUE;
                  }
                  else {
                    if (siteElement === 'postalCode' && item[siteElement].value.length < 5) {
                      this.checkForFilled = APP_CONST.FALSE;
                      this.zipcodeValidationError = APP_CONST.TRUE;
                      item[siteElement].errorStatus = APP_CONST.TRUE;
                      item[siteElement].zipcodeError = APP_CONST.TRUE;
                      item[siteElement].invalidZipCode = APP_CONST.FALSE;
                    }
                    else {
                      if (siteElement === 'postalCode' && item[siteElement].value === '00000') {
                        this.checkForFilled = APP_CONST.FALSE;
                        this.zipcodeValidationError = APP_CONST.TRUE;
                        item[siteElement].zipcodeError = APP_CONST.FALSE;
                        item[siteElement].errorStatus = APP_CONST.TRUE;
                        item[siteElement].invalidZipCode = APP_CONST.TRUE;
                      }
                      else {
                        item[siteElement].errorStatus = APP_CONST.FALSE;
                        this.zipcodeValidationError = APP_CONST.FALSE;
                      }
                    }
                    if (siteElement === 'siteStartDate' && item[siteElement].siteStartDateErr) {
                      this.checkForFilled = APP_CONST.FALSE;
                      item[siteElement].errorStatus = APP_CONST.TRUE;
                    }
                    if (siteElement === 'siteEndDate' && item[siteElement].siteEndDateErr) {
                      this.checkForFilled = APP_CONST.FALSE;
                      item[siteElement].errorStatus = APP_CONST.TRUE;
                    }

                    if (siteElement === 'siteName') {
                      const isDuplicate = this.find_duplicate_in_site(sitePanels);

                      if (isDuplicate.length > 0) {
                        isDuplicate.forEach((element: any) => {
                          if (element === item[siteElement].value) {
                            this.sitePanelDuplicateError = APP_CONST.TRUE;
                            item[siteElement].errorStatus = APP_CONST.TRUE;
                            this.checkForFilled = APP_CONST.FALSE;
                          }
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

      if (this.checkForFilled) {
        this.skipTab(accordionIndex);
      }

      this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((sitePanel: any) => {
        Object.keys(sitePanel).forEach((siteKey: any) => {
          if (typeof (sitePanel[siteKey]) == 'object' && siteKey !== 'siteAddress2') {
            if (sitePanel[siteKey].errorStatus == true || sitePanel[siteKey].value == '') {
              sitetick = false;
            }
          }
        });
      });
      this.tickStatuses[APP_CONST.ONE] = sitetick;

    }
    else if (accordionIndex === APP_CONST.TWO) {
      this.checkForFilledManager = APP_CONST.TRUE;
      const sessionManagers = accordionObj.sessionManagers;
      const validDomains = APP_CONST.NON_BX_VALID_EMAILS;

      if (sessionManagers.length === 0) {
        this.sessionMgrPanelError = APP_CONST.TRUE;
        return false;
      }
      else {
        this.sessionMgrPanelError = APP_CONST.FALSE;

        sessionManagers.forEach((item: any) => {
          for (const sessionManagerElement in item) {
            if (sessionManagerElement !== APP_CONST.OPEN_STATUS && sessionManagerElement !== APP_CONST.DELETE_POPUP) {
              if (sessionManagerElement !== APP_CONST.AREA_INVITE) {
                if (sessionManagerElement === APP_CONST.CITE_OBJECTS) {
                  if (item[sessionManagerElement][0].siteName.value === 'Select Site/Session') {
                    item[sessionManagerElement][0].siteName.errorStatus = APP_CONST.TRUE;
                  }
                  else {
                    item[sessionManagerElement][0].siteName.errorStatus = APP_CONST.FALSE;
                    item['phoneNumber'].errorStatus = APP_CONST.FALSE;
                  }
                }
                else if (sessionManagerElement === 'lastName') {
                  item[sessionManagerElement].lNameAlphaCheck = APP_UTILITIES.validateAlphaOnly(item[sessionManagerElement].value);
                  item[sessionManagerElement].lNameMinimumLengthCheck = APP_UTILITIES.checkMinimumLength(item[sessionManagerElement].value);
                  if (item[sessionManagerElement].value.length === 0) {
                    this.lNameError = APP_CONST.FALSE;
                    item[sessionManagerElement].lNameError = APP_CONST.FALSE;
                    item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                  }
                  else if ((item[sessionManagerElement].value.length > 0)) {
                    if (!item[sessionManagerElement].lNameAlphaCheck || item[sessionManagerElement].lNameMinimumLengthCheck) {
                      item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                      item[sessionManagerElement].lNameError = !item[sessionManagerElement].lNameAlphaCheck;
                      this.lNameError = APP_CONST.TRUE;
                    }
                    else if ((item[sessionManagerElement].lNameAlphaCheck)) {
                      this.lNameError = APP_CONST.FALSE;
                      item[sessionManagerElement].lNameError = APP_CONST.FALSE;
                      item[sessionManagerElement].errorStatus = APP_CONST.FALSE;
                      item['phoneNumber'].errorStatus = APP_CONST.FALSE;
                    }
                  }

                }
                else if (sessionManagerElement === 'firstName') {
                  item[sessionManagerElement].fNameAlphaCheck = APP_UTILITIES.validateAlphaOnly(item[sessionManagerElement].value);
                  item[sessionManagerElement].fNameMinimumLengthCheck = APP_UTILITIES.checkMinimumLength(item[sessionManagerElement].value);
                  if (item[sessionManagerElement].value.length === 0) {
                    this.fNameError = APP_CONST.FALSE;
                    item[sessionManagerElement].fNameError = APP_CONST.FALSE;
                    item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                  }
                  else if ((item[sessionManagerElement].value.length > 0)) {
                    if (!item[sessionManagerElement].fNameAlphaCheck || item[sessionManagerElement].fNameMinimumLengthCheck) {
                      item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                      item[sessionManagerElement].fNameError = !item[sessionManagerElement].fNameAlphaCheck;
                      this.fNameError = APP_CONST.TRUE;
                    }
                    else if ((item[sessionManagerElement].fNameAlphaCheck)) {
                      this.fNameError = APP_CONST.FALSE;
                      item[sessionManagerElement].fNameError = APP_CONST.FALSE;
                      item[sessionManagerElement].errorStatus = APP_CONST.FALSE;
                      item['phoneNumber'].errorStatus = APP_CONST.FALSE;
                    }
                  }
                }
                else if (sessionManagerElement === 'email') {
                  if (item[sessionManagerElement].value !== '') {
                    const isValidEmail = APP_UTILITIES.validateEmaiOnly(item[sessionManagerElement].value, validDomains);
                    if (!isValidEmail) {
                      this.duplicateEmailError = APP_CONST.FALSE;
                      this.emailValidationError = APP_CONST.TRUE;
                      item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                      item[sessionManagerElement].emailError = APP_CONST.TRUE;
                    }
                    else {
                      const duplicateEmail = this.find_duplicate_in_array(sessionManagers);
                      if (duplicateEmail.length > 0) {
                        duplicateEmail.forEach((element: any) => {
                          if (element === item[sessionManagerElement].value) {
                            this.duplicateEmailError = APP_CONST.TRUE;
                            item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                            item[sessionManagerElement].emailError = APP_CONST.FALSE;
                          }
                        });
                      }
                      else {
                        this.duplicateEmailError = APP_CONST.FALSE;
                        item[sessionManagerElement].errorStatus = APP_CONST.FALSE;
                        item['email'].emailError = APP_CONST.FALSE;
                        this.emailValidationError = APP_CONST.FALSE;
                        item['phoneNumber'].errorStatus = APP_CONST.FALSE;
                      }
                    }
                  }
                  else {
                    item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                  }
                }
              }
            }
          }
        });
        sessionManagers.forEach((item: any) => {
          for (const sessionManagerElement in item) {
            if (sessionManagerElement !== APP_CONST.OPEN_STATUS && sessionManagerElement !== APP_CONST.DELETE_POPUP) {
              if (sessionManagerElement !== APP_CONST.AREA_INVITE) {
                if (sessionManagerElement !== 'phoneNumber') {
                  if (item[sessionManagerElement].errorStatus === true) {
                    this.checkForFilledManager = false;
                  }
                }
              }
            }
          }
        });
        if (this.checkForFilledManager === true) {
          sessionManagers.forEach((item: any) => {
            for (const sessionManagerElement in item) {
              if (sessionManagerElement !== APP_CONST.OPEN_STATUS && sessionManagerElement !== APP_CONST.DELETE_POPUP) {
                if (sessionManagerElement !== APP_CONST.AREA_INVITE) {
                  if (sessionManagerElement === 'phoneNumber') {
                    const checkPhoneNumberValue = APP_UTILITIES.removeSpecialCharacters(item[sessionManagerElement].value);
                    if (this.checkForFilledManager) {
                      if (checkPhoneNumberValue.length > 0 && checkPhoneNumberValue.length < 10) {
                        this.phoneNoError = APP_CONST.TRUE;
                        item[sessionManagerElement].phoneError = APP_CONST.TRUE;
                        item[sessionManagerElement].errorStatus = APP_CONST.TRUE;
                        this.checkForFilledManager = APP_CONST.FALSE;
                      }
                      else {
                        this.phoneNoError = APP_CONST.FALSE;
                        item[sessionManagerElement].phoneError = APP_CONST.FALSE;
                        item[sessionManagerElement].errorStatus = APP_CONST.FALSE;
                        this.checkForFilledManager = APP_CONST.TRUE;
                        item[sessionManagerElement].value = (checkPhoneNumberValue === '')
                          ? ''
                          : item[sessionManagerElement].value;
                      }
                    }
                  }

                }
              }
            }
          });
        }
      }
      if (this.checkForFilledManager) {
        return;
      }
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((sessionManager: any) => {
        Object.keys(sessionManager).forEach((siteKey: any) => {
          if (siteKey != 'phoneNumber' && siteKey !== 'citeObjects' && siteKey !== 'textAreaInvite') {
            if (typeof (sessionManager[siteKey]) == 'object') {
              if (sessionManager[siteKey].errorStatus == true || sessionManager[siteKey].value == '') {
                managetick = false;
              }
            }
          }
        });
      });
      this.tickStatuses[APP_CONST.TWO] = managetick;
    }
  }

  public togglePanel(panelIndex: number, rootIndex: number, from?: string | undefined) {
    this.siteDaysArray = [];
    if (rootIndex === APP_CONST.ONE) {
      this.accordionTilesArray[rootIndex] && this.accordionTilesArray[rootIndex].sitePanels.forEach((item: any, index: number) => {
        if (index !== panelIndex) {
          item.openStatus = APP_CONST.FALSE;
        }
      });

      const currentPanel = this.accordionTilesArray && this.accordionTilesArray[rootIndex] && this.accordionTilesArray[rootIndex].sitePanels[panelIndex];
      const checkPanelStatus = currentPanel && currentPanel.openStatus;

      from == undefined
        ? (checkPanelStatus
          ? currentPanel && (currentPanel.openStatus = APP_CONST.FALSE)
          : currentPanel && (currentPanel.openStatus = APP_CONST.TRUE))
        : currentPanel.openStatus = APP_CONST.TRUE;

      const selectedDaysStr = currentPanel
        ? currentPanel.selectedDays.value
        : '';
      this.siteDaysArray = selectedDaysStr.length
        ? selectedDaysStr.split(',')
        : [];
    }
    else if (rootIndex === APP_CONST.TWO) {
      this.accordionTilesArray[rootIndex] && this.accordionTilesArray[rootIndex].sessionManagers.forEach((item: any, index: number) => {
        if (index !== panelIndex) {
          item.openStatus = APP_CONST.FALSE;
        }
      });

      const currentManager = this.accordionTilesArray && this.accordionTilesArray[rootIndex] && this.accordionTilesArray[rootIndex].sessionManagers[panelIndex];
      const checkPanelStatus = currentManager && currentManager.openStatus;
      from == undefined
        ? (checkPanelStatus
          ? currentManager && (currentManager.openStatus = APP_CONST.FALSE)
          : currentManager && (currentManager.openStatus = APP_CONST.TRUE))
        : currentManager.openStatus = APP_CONST.TRUE;
    }

  }

  public addSite(index: number, from?: string | undefined) {
    this.siteStartDateErr = false;
    this.siteEndDateErr = false;
    this.siteDaysArray = [];
    if (index === APP_CONST.ONE) {
      this.checkForFilled = APP_CONST.TRUE;
      this.sitePanelError = APP_CONST.FALSE;
      if (this.addSiteMount && from == undefined && this.accordionTilesArray[index].sitePanels.length) {
        this.addSiteMount = false;
      }
      else {
        const addSite = JSON.parse(JSON.stringify(APP_CONST.SITE_FORM_FIELDS));
        this.accordionTilesArray[index].sitePanels.push(addSite);
      }
      this.accordionTilesArray[index].sitePanels.forEach((item: any, sitePanelIndex: number) => {
        if (sitePanelIndex !== this.accordionTilesArray[index].sitePanels.length - 1) {
          item.openStatus = APP_CONST.FALSE;
        }
      });
      setTimeout(() => {
        const lastIndex = this.accordionTilesArray[index].sitePanels.length - 1;
        const ele: any = document.getElementById('sitePanel' + lastIndex);
        if (ele) {
          ele.scrollIntoView(true);
        }
      }, 20);
    }
    else if (index === APP_CONST.TWO) {
      this.checkForFilledManager = APP_CONST.TRUE;
      this.sessionMgrPanelError = APP_CONST.FALSE;
      this.accordionTilesArray[index].sessionManagers.push({
        openStatus: APP_CONST.TRUE,
        deletePopup: APP_CONST.FALSE,
        citeObjects: [{
          openStatus: APP_CONST.FALSE,
          deletePopup: APP_CONST.FALSE,
          siteName: {
            value: PROGRAM_CONST.LABEL_SELECT_SITE,
            errorStatus: ''
          },
          siteAddress: {
            value: '',
            errorStatus: ''
          },
          siteAddress2: {
            value: '',
            errorStatus: ''
          },
          city: {
            value: '',
            errorStatus: ''
          },
          state: {
            value: '',
            errorStatus: ''
          },
          postalCode: {
            value: '',
            errorStatus: ''
          },
          siteStartDate: {
            value: '',
            errorStatus: '',
            siteStartDateErr: false
          },
          siteEndDate: {
            value: '',
            errorStatus: '',
            siteEndDateErr: false
          },
          selectedDays: {
            value: '',
            errorStatus: ''
          }
        }],
        firstName: {
          value: '',
          errorStatus: ''
        },
        lastName: {
          value: '',
          errorStatus: ''
        },
        email: {
          value: '',
          errorStatus: ''
        },
        phoneNumber: {
          value: '',
          errorStatus: ''
        },
        textAreaInvite: {
          value: '',
          errorStatus: ''
        }
      });

      this.accordionTilesArray[index].sessionManagers.forEach((item: any, sessionManagerIndex: number) => {
        if (sessionManagerIndex !== this.accordionTilesArray[index].sessionManagers.length - 1) {
          item.openStatus = APP_CONST.FALSE;
        }
      });

      setTimeout(() => {
        const lastIndex = this.accordionTilesArray[index].sessionManagers.length - 1;
        const ele: any = document.getElementById('sessionManager' + lastIndex);
        if (ele) {
          ele.scrollIntoView(true);
        }
      }, 20);
    }
  }

  public fillDropdown(panelIndex: number, dropType: string, openStatus: boolean) {
    this.dropdownToOpen.panelIndex = panelIndex;
    this.dropdownToOpen.dropType = dropType;
    this.dropdownToOpen.openStatus = openStatus;
  }

  public openDropdown(panelIndex: number, type: string, close?: boolean) {
    const openCheck = this.dropdownToOpen.openStatus
      ? APP_CONST.FALSE
      : (close
        ? APP_CONST.FALSE
        : APP_CONST.TRUE);
    this.fillDropdown(panelIndex, type, openCheck);
  }

  openDropdownState() {
    this.openDropSiteStatus = true;
  }
  openDropdownSite() {
    this.openDropSiteStatus = true;
  }
  public selectOption(type: string, panelIndex: number, place: string | object) {
    if (type === APP_CONST.CITIES_STRING || type === APP_CONST.STATES) {
      if (type === APP_CONST.STATES) {
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].state['value'] = place;
        this.fillDropdown(APP_CONST.MINUS_ONE, '', APP_CONST.FALSE);
      }
      else {
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].city.value = place;
        this.fillDropdown(APP_CONST.MINUS_ONE, '', APP_CONST.FALSE);
      }
    }
    else {
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers[panelIndex].citeObjects.pop();
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers[panelIndex].citeObjects.push(JSON.parse(JSON.stringify(place)));
      this.fillDropdown(APP_CONST.MINUS_ONE, '', APP_CONST.FALSE);
    }

  }

  public openConfirmBox(panelIndex: number, accordionIndex: number) {
    let popupStatus = '';
    if (accordionIndex === APP_CONST.ONE) {
      popupStatus = this.accordionTilesArray[accordionIndex].sitePanels[panelIndex].deletePopup;
      popupStatus
        ? this.accordionTilesArray[accordionIndex].sitePanels[panelIndex].deletePopup = APP_CONST.FALSE
        : this.accordionTilesArray[accordionIndex].sitePanels[panelIndex].deletePopup = APP_CONST.TRUE;
      this.COUNT = 0;
    }
    else if (accordionIndex === APP_CONST.TWO) {
      popupStatus = this.accordionTilesArray[accordionIndex].sessionManagers[panelIndex].deletePopup;
      popupStatus
        ? this.accordionTilesArray[accordionIndex].sessionManagers[panelIndex].deletePopup = APP_CONST.FALSE
        : this.accordionTilesArray[accordionIndex].sessionManagers[panelIndex].deletePopup = APP_CONST.TRUE;
      this.COUNT = 0;
    }
  }

  public closeDeletePopups(event: any) {
    if (typeof (event.target.className) !== 'object' && event.target.className && event.target.className !== APP_CONST.POPUP_TEXT && event.target.className !== APP_CONST.DELETE_CONTAINER) {
      this.fillDropdown(APP_CONST.MINUS_ONE, '', APP_CONST.FALSE);
      this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((item: any) => {
        item.deletePopup = APP_CONST.FALSE;
      });
      this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((item: any) => {
        item.deletePopup = APP_CONST.FALSE;
      });
    }
  }

  enforceFormat(event: any) {
    if (!APP_UTILITIES.isNumericInput(event) && !APP_UTILITIES.isModifierKey(event)) {
      event.preventDefault();
    }
  }

  checkPhoneFormat(event: any, managerIndex: number) {
    if (APP_UTILITIES.isModifierKey(event)) {
      return; 
    }

    const target = event.target;
    const input = this.accordionTilesArray[APP_CONST.TWO].sessionManagers[managerIndex].phoneNumber && this.accordionTilesArray[2].sessionManagers[managerIndex].phoneNumber.value.replace(/\D/g, '').substring(APP_CONST.ZERO, APP_CONST.TEN);
    const zip = input && input.substring(APP_CONST.ZERO, APP_CONST.THREE);
    const middle = input && input.substring(APP_CONST.THREE, APP_CONST.SIX);
    const last = input && input.substring(APP_CONST.SIX, APP_CONST.TEN);

    if (input && input.length > APP_CONST.SIX) {
      target.value = `(${zip}) ${middle}-${last}`; 
    }
    else if (input && input.length > APP_CONST.THREE) {
      target.value = `(${zip}) ${middle}`; 
    }
    else if (input && input.length > APP_CONST.ZERO) {
      target.value = `(${zip}`; 
    }
    this.accordionTilesArray[2].sessionManagers[managerIndex].phoneNumber.value = target.value;
  }

  formatPhoneNumber(phoneNumberString: any) {
    APP_UTILITIES.formatNumber(phoneNumberString);
  }

  public getProgramId() {
    let pID = 0;
    const userRole: any = APP_UTILITIES.getCookie('user_role');
    const userRoles = JSON.parse(userRole);
    userRoles && userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('programId')) {
        pID = item.programId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('programId');
        pID = Number(programId);
      }

    });
    return pID;
  }

  onlyNumber($event: any) {
    const keyCode = ($event.keyCode
      ? $event.keyCode
      : $event.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      $event.preventDefault();
    }
  }

  returnProgramFilledObject(typeCall: string, finalSubmit: boolean, checkForFill: string = '') {
    this.phoneNumberLength = true;
    let checkFill = false;
    let accountId = 0;
    if (this.$route.name === 'Create Program') {
      const getAccountIdFromCookie: any = APP_UTILITIES.getCookie('accountId');
      accountId = JSON.parse(getAccountIdFromCookie);
    }
    else {
      accountId = this.accountId;
    }
    const programnObj: any = {
      programAdminId: this.programId,
      isFinalSubmit: (this.$route.name == PROGRAM_CONST.ROUTE_EDIT_PROGRAM)
        ? true
        : finalSubmit,
      validationMode: 1,
      IsProgramDateChanged: this.programDateChanged,
      programDto: {
        id: (this.$route.name == PROGRAM_CONST.ROUTE_EDIT_PROGRAM)
          ? Number(APP_UTILITIES.getCookie('editProgramId'))
          : (this.$route.name === 'Configure Program'
            ? this.getProgramId()
            : 0),
        roleId: 0,
        accId: accountId,
        name: '',
        description: '',
        startDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
        endDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
        numberOfScholars: 0,
        bxPartnerLeadFirstName: '',
        bxPartnerLeadLastName: '',
        bxSuccessLeadFirstName: '',
        bxSuccessLeadLastName: '',
        status: 1,
        noOfSites: this.accordionTilesArray[APP_CONST.ONE].sitePanels.length
      },
      sitePanels: [],
      sessionManagers: [],
      questions: [],
      remainingTasks: []
    };


    let indexEnhancer = -1;
    Object.keys(programnObj.programDto).forEach(element => {
      if (APP_CONST.PROGRAM_SAVE_KEYS.indexOf(element) !== -1) {
        indexEnhancer = indexEnhancer + APP_CONST.ONE;
        const value = this.accordionTilesArray[0][APP_CONST.PROGRAM_KEYS_EXCLUDE[indexEnhancer]].value;
        programnObj.programDto[element] = (indexEnhancer === APP_CONST.ONE || indexEnhancer === APP_CONST.TWO)
          ? JSON.stringify(value).substring(APP_CONST.ONE, JSON.stringify(value).length - APP_CONST.ONE)
          : (indexEnhancer === APP_CONST.THREE)
            ? typeof (parseInt(value)) === 'number'
              ? parseInt(value)
              : APP_CONST.ZERO
            : value;
        if (indexEnhancer !== APP_CONST.THREE && value === '' && typeCall !== PROGRAM_CONST.SAVE_BTN_CLICK) {
          this.enableSave = false;
          if (checkForFill && checkForFill === PROGRAM_CONST.ACTION_FILLING) {
            checkFill = true;
          }
        }
      }
    });
    indexEnhancer = -1;
    if ((this.accordionTilesArray[APP_CONST.ONE].sitePanels && this.accordionTilesArray[APP_CONST.ONE].sitePanels.length === APP_CONST.ZERO) || (this.accordionTilesArray[APP_CONST.TWO].sessionManagers && this.accordionTilesArray[APP_CONST.TWO].sessionManagers.length === APP_CONST.ZERO)) {
      checkFill = true;
    }
    this.accordionTilesArray[APP_CONST.ONE].sitePanels.forEach((panel: any, index: number) => {
      const siteObj = {
        siteId: 0,
        siteName: '',
        siteAddress: '',
        siteAddress2: '',
        city: '',
        state: '',
        postalCode: '',
        status: 0,
        startDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
        endDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
        selectedDays: ''
      };
      siteObj.siteId = panel.siteId;
      siteObj.siteName = panel.siteName.value;
      siteObj.siteAddress = panel.siteAddress.value;
      siteObj.siteAddress2 = panel.siteAddress2.value;
      siteObj.city = panel.city.value;
      siteObj.state = panel.state.value;
      siteObj.postalCode = panel.postalCode.value;
      siteObj.startDate = panel.siteStartDate
        ? panel.siteStartDate.value
        : '';
      siteObj.endDate = panel.siteEndDate
        ? panel.siteEndDate.value
        : '';
      siteObj.selectedDays = panel.selectedDays.value
        ? this.getFinalSiteDays(panel.selectedDays.value)
        : '';
      let sitePanelStatus: any = [];
      let panelList: any = [];
      if (this.$route.name == PROGRAM_CONST.ROUTE_EDIT_PROGRAM) {
        sitePanelStatus = APP_UTILITIES.getCookie('sitePanelStatus');
        panelList = JSON.parse(sitePanelStatus);
      }
      siteObj.status = (this.$route.name == PROGRAM_CONST.ROUTE_EDIT_PROGRAM)
        ? panelList[index]
        : 0;
      programnObj.sitePanels.push(siteObj);
      Object.values(siteObj).forEach((item, siteIndex) => {
        if (siteIndex !== 3 && siteIndex !== 0 && siteIndex !== 7) {
          if (item === '' && typeCall !== PROGRAM_CONST.SAVE_BTN_CLICK) {
            this.enableSave = false;
            if (checkForFill && checkForFill === PROGRAM_CONST.ACTION_FILLING) {
              checkFill = true;
            }
          }
        }
      });
    });

    this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((managerObj: any) => {
      const sessionManagerObj = {
        siteName: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        personalInvitationNote: ''
      };

      sessionManagerObj.siteName = managerObj.citeObjects[APP_CONST.ZERO].siteName.value;
      sessionManagerObj.firstName = managerObj.firstName.value;
      sessionManagerObj.lastName = managerObj.lastName.value;
      sessionManagerObj.email = managerObj.email.value;
      const phoneNumber = managerObj.phoneNumber.value.replace(/\s/g, '').replace(/[()]/g, '').replace('-', '');
      if (phoneNumber != '' && phoneNumber.length > 10) {
        sessionManagerObj.phoneNumber = phoneNumber.substring(0, 10);
      }
      else if (phoneNumber === 'null' || phoneNumber === 'undefined') {
        sessionManagerObj.phoneNumber = '';
      }
      else {
        sessionManagerObj.phoneNumber = phoneNumber;
      }
      sessionManagerObj.personalInvitationNote = managerObj.textAreaInvite.value;
      programnObj.sessionManagers.push(sessionManagerObj);
      if (sessionManagerObj.phoneNumber && (sessionManagerObj.phoneNumber.length < 10)) {
        this.phoneNumberLength = false;
      }
      Object.values(sessionManagerObj).forEach((element, elementIndex) => {
        if (elementIndex !== 1 && elementIndex !== 6 && elementIndex !== 5 && (element === '' || element === 'null' || element === undefined) && typeCall !== PROGRAM_CONST.SAVE_BTN_CLICK) {
          this.enableSave = false;
          if (checkForFill && checkForFill === PROGRAM_CONST.ACTION_FILLING) {
            checkFill = true;
          }
        }
      });
    });

    programnObj.remainingTasks = this.remainingUpdatedTasks;
    if (checkForFill && checkForFill === PROGRAM_CONST.ACTION_FILLING) {
      return checkFill;
    }

    if (typeCall === PROGRAM_CONST.SAVE_BTN_CLICK) {
      return programnObj;
    }
    else {
      if (this.enableSave === '') {
        this.enableSave = true;
      }
    }
  }

  public removePop() {
    this.inviteResponseText = '';
    this.showPopCondition = false;
    this.showInvitePopup = false;
    this.apiResponseCode = 0;
    this.isValidate = true;
    const popupPopDisplay = <HTMLInputElement>document.getElementById(PROGRAM_CONST.ELEMENTS_INVITE_MANAGER_SENT);
    if (popupPopDisplay) {
      popupPopDisplay.style.display = 'none';
      setTimeout(() => {
        const popupNewPopDisplay = <HTMLInputElement>document.getElementById(PROGRAM_CONST.ELEMENTS_INVITE_MANAGER_SENT);
        if (popupNewPopDisplay && (popupNewPopDisplay.style.display !== 'none')) {
          popupNewPopDisplay.style.display = 'none';
        }
        const popupDisplay = <any>document.getElementsByTagName('html');
        popupDisplay[0].classList.remove('uk-modal-page');
      }, 1000);
    }
    this.routeToChange = false;
    this.$router.push('/home');
  }

  goToHome() {
    this.showPopCondition = false;
    this.$router.push('/home');
    this.$emit('loadNav');
  }

  public checkSiteMgrList() {
    let flag = true;
    this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((element: any) => {
      if (element.citeObjects[APP_CONST.ZERO].siteName.errorStatus === APP_CONST.TRUE) {
        flag = APP_CONST.FALSE;
      }

    });
    return flag;
  }

  saveProgramConfigure() {
    const checkForDuplicates = this.hasNoDuplicates(this.accordionTilesArray[APP_CONST.ONE].sitePanels);
    const checkForDuplicatesEmail = this.hasNoDuplicateEmail(this.accordionTilesArray[APP_CONST.TWO].sessionManagers);
    if (checkForDuplicates) {
      if (checkForDuplicatesEmail) {
        if (!this.returnProgramFilledObject('', false, PROGRAM_CONST.ACTION_FILLING)) {
          const programnObj = this.returnProgramFilledObject(APP_CONST.SAVE_BUTTON_CONFIGURE, APP_CONST.TRUE);
          this.nextTab(0, 'fromConfig');
          this.nextTab(1);
          this.nextTab(2);

          if (this.phoneNumberLength) {
            if (this.checkSiteMgrList()) {
              if (this.accordionTilesArray[APP_CONST.ONE].sitePanels.length == 0) {
                alert(PROGRAM_CONST.ERR_SITE_PANEL);
              }
              else {
                this.showPopCondition = true;
                this.showInvitePopup = false;
                this.isValidate = false;
                programConfigureStore.saveProgramConfigure(programnObj).then((response: any) => {
                  this.saveProgramConfigureRes(response, programnObj);

                });
                this.populatedSaveObj = programnObj;
                this.routeToChange = false;
                APP_UTILITIES.setCookie('isProgramConfigured', JSON.stringify(true), 1);
              }
            }
          }
          else {
            this.showPopCondition = false;
            this.removePop();
          }
        }
        else {
          this.openTile(0, 'validation');
          this.nextTab(0, 'fromConfig');
          this.nextTab(1);
          this.nextTab(2);
          this.showPopCondition = false;
          this.removePop();
        }
      }
      else {
        this.duplicateEmailError = APP_CONST.TRUE;
        this.nextTab(2);
        this.openTile(2);
        this.showPopCondition = false;
        this.removePop();
      }
    }
    else {
      this.sitePanelDuplicateError = true;
      this.openTile(1);
      this.nextTab(1);
      this.showPopCondition = false;
      this.removePop();
    }


  }

  saveProgramConfigureRes(response: any, programnObj: any) {
    if (response.status == APP_CONST.RESPONSE_200) {
      this.showInvitePopup = true;
      this.apiResponseCode = APP_CONST.RESPONSE_200;
      this.inviteResponseText = response.data.value;
      setTimeout(() => {
        if (UIkit.modal('#invite-manager-sent')) {
          UIkit.modal('#invite-manager-sent').show();
        }
      }, 1000);
      setTimeout(() => {
        if (this.$route.name == 'Create Program') {
          this.firstTimeSequenceCompleteCheck = [true, false, false];
          this.tickStatuses = [false, false, false];
          this.questionsFetch = [];
        }
      }, 1000);
    }
    else {
      this.apiResponseCode = APP_CONST.RESPONSE_400;
      if (response.status === APP_CONST.RESPONSE_400 && response.data.message.name === 'Error_UserAlreadyRegistered') {
        this.openTile(2);
        if (response.data.error && response.data.error.length > 0) {
          response.data.error.forEach((element: any) => {
            this.accordionTilesArray[APP_CONST.TWO].sessionManagers.forEach((siteMgr: any) => {
              if (siteMgr.email.value === element) {
                siteMgr.email.errorStatus = APP_CONST.TRUE;
                this.duplicateEmailError = APP_CONST.TRUE;
              }
            });
          });
        }
      }
    }
  }

  updatedEditedData(event: { programId: number; status: number }) {
    const programnObj = this.returnProgramFilledObject(APP_CONST.SAVE_BUTTON_CONFIGURE, APP_CONST.FALSE);
    programConfigureStore.updatedProgramWithEditedData(programnObj).then((response: any) => {
      if (programConfigureStore.UpdatedProgramRes.UpdatedProgramRes.status === APP_CONST.RESPONSE_200) {
        this.backToProgramList();
      }
    }).catch((err: any) => {
    });
  }

  public getAccountId() {
    const getAccountIdFromCookie: any = APP_UTILITIES.getCookie('accountId');
    const accountId = JSON.parse(getAccountIdFromCookie);
    return accountId;
  }

  public getDataForCreateProgram() {
    const dataToSend = {
      accountId: this.getAccountId(),
      programId: this.programId
    };
    programConfigureStore.getAutoSaveProgramConfig(dataToSend).then((newData: any) => {
      const payloadSend = {
        productId: programConfigureStore.productId,
        programStartDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
        programEndDate: JSON.stringify(new Date()).replace(/['"]+/g, ''),
      };
      if (newData.data && newData.data.programDto) {
        payloadSend.programStartDate = newData.data.programDto.startDate;
        payloadSend.programEndDate = newData.data.programDto.endDate;
        if (newData.data.remainingTasks && newData.data.remainingTasks.length > 0) {
          this.remainTasksAPIresponse = newData.data.remainingTasks;
        }
      }

    });
  }

  @Watch('getUserDetail', { deep: true })
  checkForRole(data: any) {
    this.getUserDetailWatcher(data);
  }

  getUserDetailWatcher(watchData: any) {
    this.firstTimeSequenceCompleteCheck = [true, false, false];
    this.tickStatuses = [false, false, false];
    const objSelection = watchData.userRoles.filter((item: any) => item.roleId === 5 || item.roleId === 4)[0];
    this.accountId = this.$route.name === 'Configure Program'
      ? (objSelection && objSelection.accountId
        ? objSelection.accountId
        : watchData.userRoles[0].accountId)
      : (this.$route.name !== 'Configure Program'
        ? this.getGlobalState.accountId
        : 0);
    if (this.$route.name === 'Configure Program') {
      if (objSelection && objSelection.programId) {
        this.programId = objSelection.programId;
        const programId = JSON.parse(JSON.stringify(objSelection.programId));
        programConfigureStore.getProgramConfigObjProgramPage(programId).then((data: any) => {
          if (data && data.data.programDto) {
            const dataToSend = {
              accountId: data.data.programDto.accId,
              programId: this.programId,
            };
            programConfigureStore.getAutoSaveProgramConfig(dataToSend).then((newData: any) => {
              const payloadSend = {
                productId: programConfigureStore.productId,
                programStartDate: data.data.programDto.startDate,
                programEndDate: data.data.programDto.endDate
              };
              if (newData.data && newData.data.programDto) {
                payloadSend.programStartDate = newData.data.programDto.startDate;
                payloadSend.programEndDate = newData.data.programDto.endDate;
                if (newData.data.remainingTasks && newData.data.remainingTasks.length > 0) {
                  this.remainTasksAPIresponse = newData.data.remainingTasks;
                }
              }
            });
          }
        });
      }
    }
    else if (this.$route.name === 'Create Program') {
      this.getDataForCreateProgram();
    }
    else if (this.$route.name === PROGRAM_CONST.ROUTE_EDIT_PROGRAM) {
      const programId: number = Number(APP_UTILITIES.getCookie('editProgramId'));
      programConfigureStore.getProgramDataForEdit(programId).then((data: any) => {
        if (data.data && data.data.programDto) {
          this.programId = data.data.programDto.id;
          this.accountId = data.data.programDto.accId;
          this.programDateChanged = data.data.isProgramDateChanged;
          this.programAdminId = data.data.programAdminId;
          const sitePanelStatus: any = [];
          data.data.sitePanels.forEach((value: any, index: number) => {
            sitePanelStatus.push(value.status);
          });
          APP_UTILITIES.setCookie('sitePanelStatus', JSON.stringify(sitePanelStatus), 1);
        }
      });
    }
    else {
      this.accordionTilesArray = JSON.parse(JSON.stringify(APP_CONST.PROFILE_ACCORDION_TILES));
      if (this.accordionTilesArray[APP_CONST.ONE].sessionPanels.length === 0 && this.accordionTilesArray[APP_CONST.TWO].sessionManagers.length === 0) {
        this.accordionTilesArray[APP_CONST.ONE].sitePanels.push({
          openStatus: false,
          deletePopup: false,
          siteName: {
            value: '',
            errorStatus: ''
          },
          siteAddress: {
            value: '',
            errorStatus: ''
          },
          siteAddress2: {
            value: '',
            errorStatus: ''
          },
          city: {
            value: '',
            errorStatus: ''
          },
          state: {
            value: '',
            errorStatus: ''
          },
          postalCode: {
            value: '',
            errorStatus: ''
          },
          siteStartDate: {
            value: '',
            errorStatus: '',
            siteStartDateErr: false
          },
          siteEndDate: {
            value: '',
            errorStatus: '',
            siteEndDateErr: false
          },
          selectedDays: {
            value: '',
            errorStatus: ''
          }
        });
        this.accordionTilesArray[APP_CONST.TWO].sessionsManagers.push({
          openStatus: false,
          deletePopup: false,
          citeObjects: [{
            openStatus: false,
            siteName: {
              value: PROGRAM_CONST.LABEL_SELECT_SITE,
              errorStatus: ''
            },
            siteAddress: {
              value: '',
              errorStatus: ''
            },
            siteAddress2: {
              value: '',
              errorStatus: ''
            },
            city: {
              value: '',
              errorStatus: ''
            },
            state: {
              value: '',
              errorStatus: ''
            },
            postalCode: {
              value: '',
              errorStatus: ''
            },
            siteStartDate: {
              value: '',
              errorStatus: '',
              siteStartDateErr: false
            },
            siteEndDate: {
              value: '',
              errorStatus: '',
              siteEndDateErr: false
            },
            selectedDays: {
              value: '',
              errorStatus: ''
            }
          }],
          firstName: {
            value: '',
            errorStatus: ''
          },
          lastName: {
            value: '',
            errorStatus: ''
          },
          email: {
            value: '',
            errorStatus: ''
          },
          phoneNumber: {
            value: '',
            errorStatus: ''
          },
          textAreaInvite: {
            value: '',
            errorStatus: ''
          }
        });
      }
    }
  }


  @Watch('updatedProgramConfig', { deep: true })
  onConfigProgramResponse(val: any, oldVal: any) {
    const tilesArray = this.accordionTilesArray;
    const configResponse = val;
    this.programDateChanged = false;
    if (configResponse && configResponse.data && configResponse.data.programDto) {
      if (configResponse.data.programDto && tilesArray[APP_CONST.ZERO].programStartDate.value.length !== 0 && tilesArray[APP_CONST.ZERO].programEndDate.value.length !== 0 && ((tilesArray[APP_CONST.ZERO].programStartDate.value !== configResponse.data.programDto.startDate) || (tilesArray[APP_CONST.ZERO].programEndDate.value !== configResponse.data.programDto.endDate))) {
        this.programDateChanged = true;
      }
      tilesArray[APP_CONST.ZERO].programName.value = configResponse.data.programDto.name
        ? configResponse.data.programDto.name
        : '';
      tilesArray[APP_CONST.ZERO].programStartDate.value = configResponse.data.programDto.startDate
        ? configResponse.data.programDto.startDate
        : '';
      tilesArray[APP_CONST.ZERO].programEndDate.value = configResponse.data.programDto.endDate
        ? configResponse.data.programDto.endDate
        : '';
      tilesArray[APP_CONST.ZERO].numberScholars.value = configResponse.data.programDto.numberOfScholars
        ? configResponse.data.programDto.numberOfScholars
        : '';
      tilesArray[APP_CONST.ONE].sitePanels = [];
      tilesArray[APP_CONST.TWO].sessionManagers = [];
      configResponse.data.sitePanels.forEach((item: any, i: number) => {
        const siteObj = {
          openStatus: false,
          deletePopup: false,
          siteId: 0,
          siteName: {
            value: '',
            errorStatus: ''
          },
          siteAddress: {
            value: '',
            errorStatus: ''
          },
          siteAddress2: {
            value: '',
            errorStatus: ''
          },
          city: {
            value: '',
            errorStatus: ''
          },
          state: {
            value: '',
            errorStatus: ''
          },
          postalCode: {
            value: '',
            errorStatus: ''
          },
          siteStartDate: {
            value: '',
            errorStatus: '',
            siteStartDateErr: false
          },
          siteEndDate: {
            value: '',
            errorStatus: '',
            siteEndDateErr: false
          },
          selectedDays: {
            value: '',
            errorStatus: ''
          }
        };
        siteObj.siteId = item.siteId;
        siteObj.siteName.value = item.siteName
          ? item.siteName
          : '';
        siteObj.siteAddress.value = item.siteAddress
          ? item.siteAddress
          : '';
        siteObj.siteAddress2.value = item.siteAddress2
          ? item.siteAddress2
          : '';
        siteObj.city.value = item.city
          ? item.city
          : '';
        siteObj.state.value = item.state
          ? item.state
          : '';
        siteObj.postalCode.value = item.postalCode
          ? item.postalCode
          : '';
        siteObj.siteStartDate.value = item.startDate
          ? item.startDate
          : '';
        siteObj.siteEndDate.value = item.endDate
          ? item.endDate
          : '';
        siteObj.selectedDays.value = item.selectedDays
          ? item.selectedDays
          : '';
        tilesArray[APP_CONST.ONE].sitePanels.push(siteObj);
      });

      configResponse.data.sessionManagers.forEach((sessionManagerObj: any) => {
        const sessionManagerObjNew = {
          openStatus: false,
          deletePopup: false,
          citeObjects: [{
            openStatus: false,
            siteName: {
              value: PROGRAM_CONST.LABEL_SELECT_SITE,
              errorStatus: ''
            },
            siteAddress: {
              value: '',
              errorStatus: ''
            },
            siteAddress2: {
              value: '',
              errorStatus: ''
            },
            city: {
              value: '',
              errorStatus: ''
            },
            state: {
              value: '',
              errorStatus: ''
            },
            postalCode: {
              value: '',
              errorStatus: ''
            },
            siteStartDate: {
              value: '',
              errorStatus: '',
              siteStartDateErr: false
            },
            siteEndDate: {
              value: '',
              errorStatus: '',
              siteEndDateErr: false
            },
            selectedDays: {
              value: '',
              errorStatus: ''
            }
          }],
          firstName: {
            value: '',
            errorStatus: ''
          },
          lastName: {
            value: '',
            errorStatus: ''
          },
          email: {
            value: '',
            errorStatus: '',
            emailError: ''
          },
          phoneNumber: {
            value: '',
            errorStatus: '',
            phoneError: ''
          },
          textAreaInvite: {
            value: '',
            errorStatus: ''
          }
        };
        sessionManagerObjNew.citeObjects[APP_CONST.ZERO].siteName.value = sessionManagerObj.siteName
          ? sessionManagerObj.siteName
          : '';
        sessionManagerObjNew.firstName.value = sessionManagerObj.firstName
          ? sessionManagerObj.firstName
          : '';
        sessionManagerObjNew.lastName.value = sessionManagerObj.lastName
          ? sessionManagerObj.lastName
          : '';
        sessionManagerObjNew.email.value = sessionManagerObj.email
          ? sessionManagerObj.email
          : '';
        sessionManagerObjNew.phoneNumber.value = sessionManagerObj.phoneNumber
          ? sessionManagerObj.phoneNumber
          : '';
        sessionManagerObjNew.textAreaInvite.value = sessionManagerObj.personalInvitationNote
          ? sessionManagerObj.personalInvitationNote
          : '';
        tilesArray[APP_CONST.TWO].sessionManagers.push(sessionManagerObjNew);
      });
    }
  }


  public onKeyUP() {
    const programnObj = this.returnProgramFilledObject(APP_CONST.SAVE_BUTTON_CONFIGURE, APP_CONST.FALSE);
    if (this.$route.name !== PROGRAM_CONST.ROUTE_EDIT_PROGRAM) {
      programConfigureStore.saveProgramConfigure(programnObj);
    }
  }

  public autoSave(type = '') {
    this.routeToChange = true;
    const programnObj = this.returnProgramFilledObject(APP_CONST.SAVE_BUTTON_CONFIGURE, APP_CONST.FALSE);
    programConfigureStore.saveProgramConfigure(programnObj);
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    if (userRoles) {
      this.roleId = JSON.parse(userRoles)[0].roleId;
    }
    if (type == 'click-type' && this.roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID) {
      this.$router.push('/home');
    }
  }

  private closeTooltip() {
    this.showToolTip = false;
  }

  private openTooltip() {
    this.showToolTip = true;
  }


  beforeMount() {
    this.accordionTilesArray = JSON.parse(JSON.stringify(APP_CONST.PROFILE_ACCORDION_TILES));
    const routeCheck: any = this.$route.name;
    this.routeName = routeCheck;
    const user_id = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
    if (user_id) {
      commonEntry.fetchUserDetails(parseInt(user_id));
    }

    switch (this.$route.name) {
      case PROGRAM_CONST.ROUTE_CONFIG_PROGRAM:
        this.pageTitle = PROGRAM_CONST.LABEL_CONFIG_PROGRAM;
        break;

      case PROGRAM_CONST.ROUTE_CREATE_PROGRAM:
        this.pageTitle = PROGRAM_CONST.LABEL_CRATE_NEW_PROGRAM;
        break;

      case PROGRAM_CONST.ROUTE_EDIT_PROGRAM:
        this.pageTitle = PROGRAM_CONST.LABEL_EDIT_PROGRAM;
        break;

    }
    this.addSiteMount = true;
  }

  isMobile() {
    this.isMobileView = APP_UTILITIES.isMobile();
  }

  mounted() {
    this.removeAccountAdminPopup();
    this.isMobileView = APP_UTILITIES.isMobile();
    window.addEventListener('resize', APP_UTILITIES.debounce(this.isMobile));
  }

  destroyed() {
    window.removeEventListener('resize', this.isMobile);
  }

  beforeDestroy() {
    this.routeToChange = false;
    this.questionsFetch = [];
    this.questionsData = [];
  }

  checkForCorrectDate(type: string) {
    const startDate = <HTMLInputElement>document.getElementById(APP_CONST.START_DATE_ID);
    const endDate = <HTMLInputElement>document.getElementById(APP_CONST.END_DATE_ID);
    if (this.$route.name == 'Configure Program') {
      this.programDateChanged = APP_CONST.TRUE;
    }
    if (type === 'startDate') {
      this.dateIsNotGreater = false;
      const strstartDate = APP_UTILITIES.formatDate(this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value);
      const strendDate = APP_UTILITIES.formatDate(this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value);
      if (strendDate && strstartDate) {
        if (strstartDate === strendDate) {
          endDate.value = '';
          this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value = '';
        }
      }
      if (Date.parse(this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value) > Date.parse(this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value)) {
        setTimeout(() => {
          this.dateIsNotGreater = true;
          startDate.value = ''; this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value = '';
        });
      }
      else {
        this.onKeyUP();
      }
    }
    else if (type === APP_CONST.END_DATE) {
      this.dateIsGreater = false;
      if (startDate.value.length === APP_CONST.ZERO) {
        setTimeout(() => {
          endDate.value = '';
          startDate.value = '';
          this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value = '';
          this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value = '';
        });
      }
      if (Date.parse(this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value) <= Date.parse(this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value)) {
        setTimeout(() => {
          this.dateIsGreater = true;
          endDate.value = ''; this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value = '';
        });
      }
      else {
        this.onKeyUP();
      }
    }
    else {
      this.onKeyUP();
    }

  }

  hasNoDuplicates(arr: any) {
    if (arr && arr.length > 0) {
      const valueArr = arr.map((item: any, index: any) => {
        if (item.siteName.value !== '') {
          return item.siteName.value;
        }
        else {
          return index;
        }
      });
      return valueArr.every((str: string) => valueArr.indexOf(str) === valueArr.lastIndexOf(str));
    }
    else {
      return true;
    }
  }

  hasNoDuplicateEmail(arr: any) {
    if (arr && arr.length > 0) {
      const valueArr = arr.map((item: any, index: any) => {
        if (item.email.value !== '') {
          return item.email.value;
        }
        else {
          return index;
        }
      });
      return valueArr.every((str: string) => valueArr.indexOf(str) === valueArr.lastIndexOf(str));
    }
    else {
      return true;
    }
  }

  backToProgramList() {
    this.$router.push('/account/programs');
  }
  beforeUpdate() {
    const indexUptoTrue = this.firstTimeSequenceCompleteCheck.lastIndexOf(true);

    const newSequence = this.firstTimeSequenceCompleteCheck.map((checkValue: boolean, checkIndex: number) => {
      if (checkIndex <= indexUptoTrue) {
        return true;
      }
      else {
        return false;
      }
    });
    this.firstTimeSequenceCompleteCheck = newSequence;
  }

  closepopup() {
    this.apiResponseCode = 0;
    this.isValidate = true;
    if (UIkit.modal('#modal-invitation-sent')) {
      UIkit.modal('#modal-invitation-sent').hide();
    }
  }

  callHover(val: string, $event: any) {
    this.hover = true;
    this.hoverText = val;
    this.hoverVal = $event.currentTarget.innerText;
    const boundBox = $event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.hoverStyleObj = {
      top: (coordY + 25).toString() + 'px',
      left: (coordX + 1).toString() + 'px'
    };
  }

  callHoverOut(val: string) {
    this.hover = false;
    this.hoverText = val;
    this.hoverVal = '';
    this.hoverStyleObj = {};
  }
  errorIndicator(item: any, lavel: number) {
    if (item.textValue == this.accordionTilesArray[0].textValue) {
      if ((item.programName.errorStatus && item.programName.value.length === 0) || (item.programStartDate.errorStatus && item.programStartDate.value.length === 0) || (item.programEndDate.errorStatus && item.programEndDate.value.length === 0) || (item.numberScholars.errorStatus && item.numberScholars.value.length === 0)) {
        return true;
      }
    }
    if (item.textValue == this.accordionTilesArray[1].textValue) {
      if (item.sitePanels.length > 0) {
        for (let i = 0; i < item.sitePanels.length; i++) {
          if ((item.sitePanels[i].siteName.errorStatus && item.sitePanels[i].siteName.value.length === 0) ||
            (item.sitePanels[i].siteAddress.errorStatus && item.sitePanels[i].siteAddress.value.length === 0) ||
            (item.sitePanels[i].city.errorStatus && item.sitePanels[i].city.value.length === 0) ||
            (item.sitePanels[i].state.errorStatus && item.sitePanels[i].state.value.length === 0) ||
            (item.sitePanels[i].postalCode.errorStatus && item.sitePanels[i].postalCode.value.length === 0) ||
            (item.sitePanels[i].postalCode.errorStatus && item.sitePanels[i].postalCode.zipcodeError) ||
            (item.sitePanels[i].postalCode.invalidZipCode && item.sitePanels[i].postalCode.errorStatus) ||
            (item.sitePanels[i].selectedDays.errorStatus && item.sitePanels[i].selectedDays.value === '') ||
            (item.sitePanels[i].siteStartDate.errorStatus && item.sitePanels[i].siteStartDate.value.length === 0) ||
            (item.sitePanels[i].siteStartDate.siteStartDateErr) ||
            (item.sitePanels[i].siteEndDate.siteEndDateErr)
          ) {

            return true;
          }
        }


      }
      else {
        return this.sitePanelError;
      }

    }
    if (item.textValue == this.accordionTilesArray[2].textValue) {
      if (item.sessionManagers.length > 0) {
        for (let j = 0; j < item.sessionManagers.length; j++) {
          if ((item.sessionManagers[j].citeObjects[0].siteName.errorStatus) ||
            (item.sessionManagers[j].firstName.errorStatus && item.sessionManagers[j].firstName.value.length === 0) ||
            (item.sessionManagers[j].firstName.errorStatus && item.sessionManagers[j].firstName.fNameError) ||
            (item.sessionManagers[j].firstName.errorStatus && !item.sessionManagers[j].firstName.fNameError && item.sessionManagers[j].firstName.fNameMinimumLengthCheck) ||
            (item.sessionManagers[j].lastName.errorStatus && item.sessionManagers[j].lastName.value.length === 0) ||
            (item.sessionManagers[j].lastName.errorStatus && item.sessionManagers[j].lastName.lNameError) ||
            (item.sessionManagers[j].lastName.errorStatus && !item.sessionManagers[j].lastName.lNameError && item.sessionManagers[j].lastName.lNameMinimumLengthCheck) ||
            (item.sessionManagers[j].email.errorStatus && item.sessionManagers[j].email.value.length === 0) || (item.sessionManagers[j].email.emailError && item.sessionManagers[j].email.errorStatus) ||
            (this.duplicateEmailError && item.sessionManagers[j].email.errorStatus) || (item.sessionManagers[j].phoneNumber.phoneError && item.sessionManagers[j].phoneNumber.errorStatus) || (!this.phoneNumberLength)) {

            return true;
          }
        }
      }
      else {
        return this.sessionMgrPanelError;
      }

    }

    return false;
  }
  errorChildIndicator(item: any, obj: any) {
    if (obj.textValue == this.accordionTilesArray[1].textValue) {

      if ((item.siteName.errorStatus && item.siteName.value.length === 0) ||
        (item.siteAddress.errorStatus && item.siteAddress.value.length === 0) ||
        (item.city.errorStatus && item.city.value.length === 0) ||
        (item.state.errorStatus && item.state.value.length === 0) ||
        (item.postalCode.errorStatus && item.postalCode.value.length === 0) ||
        (item.postalCode.errorStatus && item.postalCode.zipcodeError) ||
        (item.postalCode.invalidZipCode && item.postalCode.errorStatus) ||
        (item.selectedDays.errorStatus && item.selectedDays.value.length === 0) ||
        (item.siteStartDate.errorStatus && item.siteStartDate.value.length === 0) ||
        (item.siteEndDate.errorStatus && item.siteEndDate.value.length === 0) ||
        (item.siteStartDate.siteStartDateErr) ||
        (item.siteEndDate.siteEndDateErr)
      ) {

        return true;
      }

    }
    if (obj.textValue == this.accordionTilesArray[2].textValue) {
      if ((item.citeObjects[0].siteName.errorStatus) ||
        (item.firstName.errorStatus && item.firstName.value.length === 0) ||
        (item.firstName.errorStatus && item.firstName.fNameError) ||
        (item.firstName.errorStatus && !item.firstName.fNameError && item.firstName.fNameMinimumLengthCheck) ||
        (item.lastName.errorStatus && item.lastName.value.length === 0) ||
        (item.lastName.errorStatus && item.lastName.lNameError) ||
        (item.lastName.errorStatus && !item.lastName.lNameError && item.lastName.lNameMinimumLengthCheck) ||
        (item.email.errorStatus && item.email.value.length === 0) || (item.email.emailError && item.email.errorStatus) ||
        (this.duplicateEmailError && item.email.errorStatus) || (item.phoneNumber.phoneError && item.phoneNumber.errorStatus) || (!this.phoneNumberLength)) {
        return true;
      }
    }
  }

  removeAccountAdminPopup() {
    const popupPopDisplay = <HTMLInputElement>document.getElementById('configure-program-confirmation');
    if (popupPopDisplay) {
      popupPopDisplay.style.display = 'none';
      setTimeout(() => {
        const popupNewPopDisplay = <HTMLInputElement>document.getElementById('configure-program-confirmation');
        if (popupNewPopDisplay && (popupNewPopDisplay.style.display !== 'none')) {
          popupNewPopDisplay.style.display = 'none';
        }
        const popupDisplay = <any>document.getElementsByTagName('html');
        popupDisplay[0].classList.remove('uk-modal-page');
      }, 1000);
    }
  }

  public addRemoveSiteDay(day: string, selectedDays: object, panelIndex: number) {
    const selectedDaysStr = (this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].selectedDays.value).replace(/'/g, '');
    this.siteDaysArray = selectedDaysStr.length
      ? selectedDaysStr.split(',')
      : [];
    if (this.siteDaysArray.includes(day)) {
      const dayIndex = this.siteDaysArray.indexOf(day);
      if (dayIndex > -1) {
        this.siteDaysArray.splice(dayIndex, 1);
      }
    }
    else {
      this.siteDaysArray.push(day);
    }
    this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].selectedDays.value = this.siteDaysArray.toString();
    this.onKeyUP();
  }

  public getFinalSiteDays(str: string) {
    const daysStr = str.replace(/'/g, '');
    let days = '';
    if (str.length !== 0) {
      const siteDaysArr = daysStr.split(',');
      if (siteDaysArr.includes('Su')) {
        days += 'Su';
      }
      if (siteDaysArr.includes('M')) {
        days = this.addCommaInString(days);
        days += 'M';
      }
      if (siteDaysArr.includes('T')) {
        days = this.addCommaInString(days);
        days += 'T';
      }
      if (siteDaysArr.includes('W')) {
        days = this.addCommaInString(days);
        days += 'W';
      }
      if (siteDaysArr.includes('Th')) {
        days = this.addCommaInString(days);
        days += 'Th';
      }
      if (siteDaysArr.includes('F')) {
        days = this.addCommaInString(days);
        days += 'F';
      }
      if (siteDaysArr.includes('S')) {
        days = this.addCommaInString(days);
        days += 'S';
      }
    }
    return days;
  }

  public addCommaInString(days: string) {
    if (days !== '') {
      days += ',';
    }
    return days;
  }

  checkForCorrectSiteDate(type: string, panelIndex: number) {
    const siteSDVal = this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.value
      ? this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.value
      : '';
    const siteSEVal = this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.value
      ? this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.value
      : '';
    const siteStartDate = siteSDVal
      ? new Date(APP_UTILITIES.formatDate(siteSDVal))
      : '';
    const siteEndDate = siteSEVal
      ? new Date(APP_UTILITIES.formatDate(siteSEVal))
      : '';
    const progStartDate = new Date(APP_UTILITIES.formatDate(this.accordionTilesArray[APP_CONST.ZERO].programStartDate.value));
    const progEndDate = new Date(APP_UTILITIES.formatDate(this.accordionTilesArray[APP_CONST.ZERO].programEndDate.value));

    if (type === 'siteStartDate') {
      this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.errorStatus = false;
    }

    if (type === 'siteEndDate') {
      this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.errorStatus = false;
    }

    if (siteStartDate) {
      if (type === 'siteStartDate' && ((siteStartDate < progStartDate) || (siteStartDate > progEndDate))) {
        this.siteStartDateErr = true;
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.siteStartDateErr = true;
      }
      else if (type === 'siteEndDate' && ((siteStartDate > siteEndDate) || (siteEndDate > progEndDate))) {
        this.siteEndDateErr = true;
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.siteEndDateErr = true;
      }
      if (siteEndDate != '' && siteStartDate > siteEndDate) {
        this.siteEndDateErr = true;
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.siteEndDateErr = true;
      }
      if ((siteStartDate >= progStartDate) && (siteStartDate <= progEndDate)) {
        this.siteStartDateErr = false;
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.siteStartDateErr = false;
      }
      if ((siteStartDate <= siteEndDate) && (siteEndDate <= progEndDate)) {
        this.siteEndDateErr = false;
        this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteEndDate.siteEndDateErr = false;
        this.onKeyUP();
      }
    }
    else {
      this.siteStartDateErr = true;
      this.accordionTilesArray[APP_CONST.ONE].sitePanels[panelIndex].siteStartDate.siteStartDateErr = true;
    }
  }
  removeErrorMsg(index: number) {
    if (index == 0) {
      this.checkForFilledProgram = true;
    }
    if (index == 1) {
      this.checkForFilled = true;
      this.zipcodeValidationError = true;
      this.sitePanelDuplicateError = true;
    }
    if (index == 2) {
      this.checkForFilledManager = true;
      this.emailValidationError = true;
      this.duplicateEmailError = true;
      this.fNameError = true;
      this.lNameError = true;
      this.phoneNoError = true;

    }

  }

  clearFormButton(index: number) {
    const data = this.accordionTilesArray[index];

    if ((index == 0 && !data.programName.value && !data.programStartDate.value && !data.programEndDate.value)
      || (index == 1 && ((data.sitePanels.length == 0) || (data.sitePanels.length >= 1 && !data.sitePanels[0].siteName.value && !data.sitePanels[0].siteAddress.value && !data.sitePanels[0].siteAddress2.value && !data.sitePanels[0].city.value && !data.sitePanels[0].state.value
        && !data.sitePanels[0].postalCode.value && !data.sitePanels[0].siteStartDate.value && !data.sitePanels[0].siteEndDate.value && !data.sitePanels[0].selectedDays.value)))
      || (index == 2 && ((data.sessionManagers.length == 0) || (data.sessionManagers.length >= 1 && !data.sessionManagers[0].firstName.value && !data.sessionManagers[0].lastName.value && !data.sessionManagers[0].email.value
        && !data.sessionManagers[0].phoneNumber.value && !data.sessionManagers[0].textAreaInvite.value && data.sessionManagers[0].citeObjects[0].siteName.value == 'Select Site/Session')))) {
      return true;
    }
    else {
      return false;
    }

  }
  finished() {
    const element: any = document.getElementById('progressBarbtnInviteSessionManager');
    element && element.classList && element.classList.add('width248');
  }
}
