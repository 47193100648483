import { getUserDetailsResponseObj, AccountInfo, getLMSLinks } from '@/services/userService/users-api';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

  @Module({
    namespaced: true,
    name: 'CommontEntryStore',
    store,
    dynamic: true
  })

class CommontEntryModule extends VuexModule {
    private userDetails: object = {};
    private firstLoginInfo: object = {};
    private lmsLinks: any[] = [];

    get userDetail(): object {
      return this.userDetails;
    }

    get FirstLoginAccountInfo(): object {
      return this.firstLoginInfo;
    }

    get LMSLinks(): object {
      return this.lmsLinks;
    }


      @Mutation
    public afterFetchMutate(payload: any) {
      const drill_available = APP_UTILITIES.getCookie('drilldown_key');
      if(APP_UTILITIES.getCookie('super_user_drilldown')  || (drill_available && (JSON.parse(drill_available).actor > 0)) || (APP_UTILITIES.getCookie('account_admin_drilldown'))){
        const userData :any = (APP_UTILITIES.getCookie('user_role'));
        payload.data.userRoles = JSON.parse(userData);
        payload.data.userRoles[0].accountId = parseInt(JSON.parse(userData)[0].accountId);
        this.userDetails = payload.data;
      }
      else {
        this.userDetails = payload.data;
      }
        
    }
    
    
      @Action({ commit: 'afterFetchMutate' })
      public fetchUserDetails(id: number): Object {
        const resultNew = async function getUserDetails(id: number): Promise<Object> {
          const promise = getUserDetailsResponseObj(id);
          const result = await promise; 
          return result as object;
        };
        return resultNew(id);
      }
    

      @Mutation
      public afterAccountInfoMutate(payload: any) {
        this.firstLoginInfo = payload.data;
      }
    
    
      @Action({ commit: 'afterAccountInfoMutate' })
      public getAccountInfo(id: number): Object {
        const resultNew = async function getUserDetails(id: number): Promise<Object> {
          const promise = AccountInfo(id);
          const result = await promise; 
          return result as object;
        };
        return resultNew(id);
      }


      @Mutation
      public afterLMSInfo(payload: any) {
        this.lmsLinks = payload;
      }
    
    
      @Action({ commit: 'afterLMSInfo' })
      public getLMSInfo(): object {
        const resultNew = async function getUserDetails(): Promise<object> {
          const promise = getLMSLinks();
          const result = await promise; 
          return result as object;
        };
        return resultNew();
      }

  }

export default getModule(CommontEntryModule);

